import {Card, Flex, Table, TableContainer, Tbody, Td, Text, Thead, Tr} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

import OrderCustomization from '@app/components/Shop/OrderInformation/OrderCustomization.tsx';
import OrderTrackingNumber from '@app/components/Shop/OrderInformation/OrderTrackingNumber';
import OrderMetaInformation from '@app/components/molecules/Order/OrderMetaInformation.tsx';
import {Order} from '@app/types/Order/Information/Order';

const OrderInformation: FC<{order: Order}> = ({order}): ReactElement => {
  return (
    <>
      <Flex>
        <OrderMetaInformation order={order} />
        <OrderTrackingNumber order={order} />
        <OrderCustomization order={order} />
      </Flex>
      <Card mt={5} mx={2} p={3}>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Td>
                  <Text as="b">Produit</Text>
                </Td>
                <Td>
                  <Text as="b">Quantité</Text>
                </Td>
                <Td>
                  <Text as="b">EAN13</Text>
                </Td>
                <Td>
                  <Text as="b">Prix</Text>
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              {order.carts.map((cart, index) => (
                <Tr key={index}>
                  <Td>
                    <Text>{cart.product.name}</Text>
                  </Td>
                  <Td>
                    <Text>{cart.quantity}</Text>
                  </Td>
                  <Td>
                    <Text>{cart.product.ean13}</Text>
                  </Td>
                  <Td>
                    <Text>{cart.price}</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default OrderInformation;
