import {useToast} from '@chakra-ui/react';
import React, {FC, ReactElement, useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import OrderPackaging from '@app/components/Shop/OrderPackaging/OrderPackaging.tsx';
import Search from '@app/components/Shop/Shipment/Search/Search';
import {useLazySearchOrderQuery} from '@app/store/camalo/orderApi';
import {shopContext} from '@app/types/ContextType';
import {Order} from '@app/types/Order/Information/Order';

const OrderPackagingApp: FC = (): ReactElement => {
  const toast = useToast();
  const {shop} = useOutletContext<shopContext>();
  const [triggerOrderSearch, {isError}] = useLazySearchOrderQuery();
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    setOrder(undefined);
  }, [isError]);

  const searchAction = async (data: string): Promise<void> => {
    await triggerOrderSearch({
      shopSlug: shop.slug!,
      query: data,
    })
      .unwrap()
      .then(order => setOrder(order))
      .catch(error => {
        toast({
          position: 'top-right',
          title: error.data.title!,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Search title={'Commande - colis'} placeholder={'Numéro de commande'} searchAction={searchAction} />
      {order && order.id && <OrderPackaging order={order} onSave={() => setOrder(undefined)} />}
    </>
  );
};

export default OrderPackagingApp;
