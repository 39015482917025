import slugify from 'slugify';

import downloadFile from '@app/helpers/DownloadFile.ts';
import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {Order} from '@app/types/Order/Information/Order';
import {Package} from '@app/types/Order/Packaging/Package.ts';
import {ShipmentResponse} from '@app/types/Shipment/ShipmentResponse.ts';

type SearchOrderQuery = {
  shopSlug: string;
  query: string;
};

type CustomizationFilesQuery = {
  shopSlug: string;
  order: Order;
};

export const orderApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    searchOrder: builder.query<Order, SearchOrderQuery>({
      query: ({shopSlug, query}) => ({
        url: Routing.generate('rest_order_search', {slug: shopSlug, query}, true),
        method: 'GET',
      }),
    }),
    customizationFiles: builder.query<Order, CustomizationFilesQuery>({
      query: ({shopSlug, order}) => ({
        url: Routing.generate('rest_order_customization_files', {slug: shopSlug, order: order.id}, true),

        method: 'GET',
        responseType: 'blob',
        responseHandler: async response => response.blob(),
      }),
      // @ts-ignore
      transformResponse: (response: Blob, meta, params: CustomizationFilesQuery) => {
        const fileName = slugify(params.order.reference, {lower: true, strict: true});
        downloadFile(response, `${fileName}.zip`);
        return undefined; // @HACK: return undefined to disable cache
      },
    }),
    orderPackaging: builder.mutation<ShipmentResponse, {shopSlug: string; orderId: number; packages: Package[]}>({
      query: ({shopSlug, orderId, packages}) => ({
        url: Routing.generate('rest_order_packaging', {slug: shopSlug, order: orderId}, true),
        method: 'PUT',
        body: {packages},
      }),
    }),
  }),
});

export const {useLazySearchOrderQuery, useLazyCustomizationFilesQuery, useOrderPackagingMutation} = orderApi;
