import {useToast} from '@chakra-ui/react';
import {useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {useOrderPackagingMutation} from '@app/store/camalo/orderApi.ts';
import {shopContext} from '@app/types/ContextType.ts';
import {Order} from '@app/types/Order/Information/Order.ts';
import {Package} from '@app/types/Order/Packaging/Package.ts';
import {Product} from '@app/types/Order/Packaging/Product.ts';

interface UseOrderPackagingReturn {
  products: Product[];
  packages: Package[];
  currentPackage: Product[];
  quantityToAdd: {[productId: number]: number | 0};
  handleQuantityChange: (productId: number, quantity: string) => void;
  addToCurrentPackage: (product: Product, quantity?: number) => void;
  addAllToCurrentPackage: (product: Product) => void;
  saveCurrentPackage: () => void;
  savePackages: () => void;
  savePackageLoading: boolean;
}

export const useOrderPackaging = (
  order: Order,
  initialProducts: Product[],
  onSave: () => void,
): UseOrderPackagingReturn => {
  const toast = useToast();
  const {shop} = useOutletContext<shopContext>();
  const [products, setProducts] = useState<Product[]>(initialProducts);
  const [packages, setPackages] = useState<Package[]>([]);
  const [currentPackage, setCurrentPackage] = useState<Product[]>([]);
  const [quantityToAdd, setQuantityToAdd] = useState<{[productId: number]: number | 0}>({});
  const [savePackage, {isLoading: savePackageLoading}] = useOrderPackagingMutation();

  const handleQuantityChange = (productId: number, quantity: string) => {
    const parsedQuantity = parseInt(quantity);
    setQuantityToAdd(prev => ({...prev, [productId]: isNaN(parsedQuantity) ? 0 : parsedQuantity}));
  };

  // Fonction pour ajouter une quantité spécifique au colis en cours
  const addToCurrentPackage = (product: Product, quantity: number = 1) => {
    if (quantity <= 0) return;

    // Vérifier si le produit existe déjà dans le colis en cours
    setCurrentPackage(prevPackage => {
      const existingItemIndex = prevPackage.findIndex(item => item.id === product.id);

      if (existingItemIndex !== -1) {
        // Si le produit est déjà dans le colis, on met à jour sa quantité
        const updatedPackage = [...prevPackage];
        updatedPackage[existingItemIndex].quantity += quantity;
        return updatedPackage;
      } else {
        // Si le produit n'est pas encore dans le colis, on l'ajoute
        return [...prevPackage, {...product, quantity}];
      }
    });

    // Mettre à jour la quantité du produit dans la liste des produits disponibles
    setProducts(prevProducts =>
      prevProducts.map(p => (p.id === product.id ? {...p, quantity: p.quantity - quantity} : p)),
    );

    // Réinitialiser la quantité personnalisée
    setQuantityToAdd(prev => ({
      ...prev,
      [product.id]: 0,
    }));
  };

  const addAllToCurrentPackage = (product: Product) => {
    addToCurrentPackage(product, product.quantity);
  };

  const saveCurrentPackage = async () => {
    if (currentPackage.length === 0) return;
    const newPackage: Package = {products: currentPackage};
    setPackages(prev => [...prev, newPackage]);
    setCurrentPackage([]);
  };

  const savePackages = async () => {
    const response = await savePackage({shopSlug: shop.slug, orderId: order.id, packages: packages})
      .unwrap()
      .then(() => {
        toast({
          position: 'top-right',
          title: 'Colis enregistrés',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onSave();
      })
      .catch(error => {
        toast({
          position: 'top-right',
          title: error.data.title!,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return {
    products,
    packages,
    currentPackage,
    quantityToAdd,
    handleQuantityChange,
    addToCurrentPackage,
    addAllToCurrentPackage,
    saveCurrentPackage,
    savePackages,
    savePackageLoading,
  };
};
