import {
  Button,
  Card,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import {FaCheck} from 'react-icons/fa6';

import {Product} from '@app/types/Order/Packaging/Product.ts';

interface AvailableProductsTableProps {
  products: Product[];
  quantityToAdd: {[productId: number]: number | 0};
  onQuantityChange: (productId: number, quantity: string) => void;
  onAddToPackage: (product: Product, quantity?: number) => void;
  onAddAllToPackage: (product: Product) => void;
}

const AvailableProductsTable: React.FC<AvailableProductsTableProps> = ({
  products,
  quantityToAdd,
  onQuantityChange,
  onAddToPackage,
  onAddAllToPackage,
}) => {
  if (products.filter(product => product.quantity > 0).length === 0) {
    return null;
  }

  return (
    <Card mt={5} mx={2} p={3}>
      <Heading as="h2" mb="4">
        Produits disponibles
      </Heading>
      <TableContainer>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Td>
                <Text as="b">Produit</Text>
              </Td>
              <Td>
                <Text as="b">Qtt</Text>
              </Td>
              <Td>
                <Text as="b">Poids (g)</Text>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Thead>
          <Tbody>
            {products
              .filter(product => product.quantity > 0)
              .sort((product1, product2) => product2.weight - product1.weight)
              .map((product, index) => (
                <Tr key={index}>
                  <Td>
                    <Text>{product.name}</Text>
                  </Td>
                  <Td>
                    <Text>{product.quantity}</Text>
                  </Td>
                  <Td>
                    <Text>{product.weight} g</Text>
                  </Td>
                  <Td>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type="number"
                        bg="white"
                        value={quantityToAdd[product.id] || ''}
                        onChange={e => onQuantityChange(product.id, e.target.value)}
                        isInvalid={(quantityToAdd[product.id] && quantityToAdd[product.id] > product.quantity) || false}
                        min="1"
                        max={product.quantity}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() => onAddToPackage(product, Number(quantityToAdd[product.id]) || 0)}
                        >
                          <FaCheck />
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Td>
                  <Td>
                    <Button colorScheme="blue" size="sm" onClick={() => onAddToPackage(product, 1)}>
                      +1
                    </Button>
                  </Td>
                  <Td>
                    <Button colorScheme="green" size="sm" onClick={() => onAddAllToPackage(product)}>
                      Ajouter tout
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AvailableProductsTable;
