import React, {FC, ReactElement} from 'react';

import OrderPackagingApp from '@app/components/Shop/OrderPackaging/OrderPackagingApp.tsx';
import LayoutShop from '@app/components/templates/LayoutShop';

const ShopOrderPackaging: FC = (): ReactElement => {
  return (
    <LayoutShop appName="Colis" needPrinterInfo>
      <OrderPackagingApp />
    </LayoutShop>
  );
};

export default ShopOrderPackaging;
