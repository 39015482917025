import {Box, Button, Card, Grid, Heading} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

import AvailableProductsTable from '@app/components/Shop/OrderPackaging/_partial/AvailableProductsTable.tsx';
import PackageProducts from '@app/components/Shop/OrderPackaging/_partial/PackageProducts.tsx';
import {useOrderPackaging} from '@app/components/Shop/OrderPackaging/hooks/useOrderPackaging.ts';
import {Order} from '@app/types/Order/Information/Order.ts';
import {Product} from '@app/types/Order/Packaging/Product.ts';

const ProductPackaging: FC<{order: Order; products: Product[]; onSave: () => void}> = ({
  order,
  products: productsList,
  onSave,
}): ReactElement => {
  const {
    products,
    packages,
    currentPackage,
    quantityToAdd,
    handleQuantityChange,
    addToCurrentPackage,
    addAllToCurrentPackage,
    saveCurrentPackage,
    savePackages,
    savePackageLoading,
  } = useOrderPackaging(order, productsList, onSave);

  return (
    <Grid gap={6}>
      {/* Colonne de gauche - Liste des produits */}
      <Box>
        <AvailableProductsTable
          products={products}
          quantityToAdd={quantityToAdd}
          onQuantityChange={handleQuantityChange}
          onAddToPackage={addToCurrentPackage}
          onAddAllToPackage={addAllToCurrentPackage}
        />
      </Box>

      {/* Colonne de droite - Colis en cours et enregistrés */}
      <Box position="relative" boxSize="full">
        <Box position="sticky" top="4" alignSelf="start">
          <Card mt={5} mx={2} p={3}>
            <PackageProducts products={currentPackage} title="Colis en cours" />
            <Button colorScheme="green" mt={5} onClick={saveCurrentPackage}>
              Valider ce colis
            </Button>
          </Card>

          <Card mt={5} mx={2} p={3}>
            <Heading size="xl" mb="4">
              Colis enregistrés
            </Heading>
            {packages.map((pkg, index) => (
              <PackageProducts key={index} products={pkg.products} title={`Colis #${index + 1}`} />
            ))}
          </Card>

          <Button
            colorScheme="green"
            disabled={products.filter(product => product.quantity > 0).length !== 0}
            mt={5}
            isLoading={savePackageLoading}
            isDisabled={savePackageLoading}
            onClick={() => savePackages()}
          >
            Enregistrer les colis
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default ProductPackaging;
