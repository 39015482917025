import React, {FC, ReactElement} from 'react';

import OrderInformationApp from '@app/components/Shop/OrderInformation/OrderInformationApp';
import LayoutShop from '@app/components/templates/LayoutShop';

const ShopOrderInformation: FC = (): ReactElement => {
  return (
    <LayoutShop appName="Information commande" needPrinterInfo>
      <OrderInformationApp />
    </LayoutShop>
  );
};

export default ShopOrderInformation;
