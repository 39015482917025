import {Heading, Table, TableContainer, Tbody, Td, Text, Thead, Tr} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

import {Product} from '@app/types/Order/Packaging/Product.ts';

const PackageProducts: FC<{title: string; products: Product[]}> = ({title, products}): ReactElement => {
  const totalWeight = products.reduce((total, product) => total + product.weight * product.quantity, 0) / 1000;

  return (
    <>
      <Heading size="md" mb="4">
        {title} - {totalWeight} kg
      </Heading>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>
                <Text as="b">Produit</Text>
              </Td>
              <Td>
                <Text as="b">Quantité</Text>
              </Td>
              <Td>
                <Text as="b">Poids total</Text>
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            {products.map((product, index) => (
              <Tr key={index}>
                <Td>
                  <Text>{product.name}</Text>
                </Td>
                <Td>
                  <Text>{product.quantity}</Text>
                </Td>
                <Td>
                  <Text>{product.weight * product.quantity} g</Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PackageProducts;
