import {Card, Heading, List, ListItem, Text} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

import {Order} from '@app/types/Order/Information/Order.ts';

const OrderMetaInformation: FC<{order: Order}> = ({order}): ReactElement => {
  return (
    <Card mt={5} mx={2} p={3}>
      <Heading as="h4" mb="4">
        Commande
      </Heading>
      <List>
        <ListItem>
          <Text as="b">Numéro de commande</Text> : {order.id}
        </ListItem>
        <ListItem>
          <Text as="b">Référence interne</Text> : {order.reference}
        </ListItem>
        <ListItem>
          <Text as="b">Référence externe</Text> : {order.externalReference}
        </ListItem>
        <ListItem>
          <Text as="b">Numéro de commande externe</Text> : {order.externalId}
        </ListItem>
        <ListItem>
          <Text as="b">Date de commande</Text> : {order.dateAdd}
        </ListItem>
      </List>
    </Card>
  );
};

export default OrderMetaInformation;
