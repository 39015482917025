import {Flex} from '@chakra-ui/react';
import React, {FC} from 'react';

import ProductPackaging from '@app/components/Shop/OrderPackaging/ProductPackaging.tsx';
import OrderMetaInformation from '@app/components/molecules/Order/OrderMetaInformation.tsx';
import {Order} from '@app/types/Order/Information/Order';
import {Product} from '@app/types/Order/Packaging/Product.ts';

const OrderPackaging: FC<{order: Order; onSave: () => void}> = ({order, onSave}) => {
  const products: Product[] = order.carts.map(cart => {
    return {
      id: cart.product.id,
      name: cart.product.name,
      quantity: cart.quantity,
      weight: cart.product.weight,
      cartId: cart.id,
    };
  });

  return (
    <>
      <Flex>
        <OrderMetaInformation order={order} />
      </Flex>
      <ProductPackaging order={order} products={products} onSave={onSave} />
    </>
  );
};

export default OrderPackaging;
